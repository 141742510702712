const ROUTES = {
  "/": {
    "name": "HomePage22f0fb664f354abbA39eEad966d7c93f",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages317a89d25a3e470dAc4f44d32e0dd8df",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage0eb43bbd02014b95809aBd20568215a2",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageF4d7f00fAa1446a0Befd56fd916b7809",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPageCe93bc80E9a14520858e624d1b3c58e5",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage3bfa0d57Bc0744b781903e8ac25be174",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesF0c2645b1e574ae9B42d805d7a193ae6",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages0cc80d9b091e4738A0d0B00722ae56e8",
    "type": "HTML",
    "key": "message-pages"
  },
  "/polytopia-2024": {
    "name": "Polytopia20244a7bde77A73a4f108cbcD18b3094fbf8",
    "type": "HTML",
    "key": "polytopia-2024"
  },
  "/polytopia-2024/workshops": {
    "name": "Polytopia2024Workshops97c55f6f04774962A12aC1d9a5e53c79",
    "type": "HTML",
    "key": "polytopia-2024-workshops"
  },
  "/resources": {
    "name": "ResourcesPageEd43810c7d9e4c539ba0209f2e1f6903",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages36e4f28cC9dc4a1dAddb96229cf8f272",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;